<template>
    <div>
        <!-- TAB INPUTS -->
        <input id="tab-1" class="page-tab-input" name="page-tab-input" type="radio" checked />
        <input id="page-tab-notifications" class="page-tab-input" name="page-tab-input" type="radio" />
        <input id="tab-3" class="page-tab-input" name="page-tab-input" type="radio" />
        <input id="tab-4" class="page-tab-input" name="page-tab-input" type="radio" />
        <input id="tab-5" class="page-tab-input" name="page-tab-input" type="radio" />

        <!-- TABS CONTAINER -->
        <div class="user-settings-header page-tabs-container">
            <h1>{{ header }}</h1>
            <label class="page-tab" for="tab-1">Dashboard Alerts</label>
            <label class="page-tab page-tab-notifications" for="page-tab-notifications">Client Notifications</label>
            <label class="page-tab" for="tab-3">Schedule View</label>
            <label class="page-tab" for="tab-4">Change Password</label>
            <label class="page-tab" for="tab-5">Add / Edit Signature</label>
        </div>

        <!-- TABBED PAGE SECTIONS -->
        <section class="tabbed-page dash-alerts">
            <ChirpSettings v-if="!loading" :data_source="rows['alert']" :level="'user'" :level_id="level_id" class="" />
        </section>

        <section class="tabbed-page notifications">
            <ChirpSettings
                v-if="!loading"
                :data_source="rows['notification']"
                :level="'user'"
                :level_id="level_id"
                class=""
            />
        </section>

        <section class="tabbed-page schedule-view">
            <!--            <h3 class="margin-0">Schedule View</h3>-->
            <!--            <div class="bottom-20">Select your default calendar views.</div>-->

            <!--            <p class="dark-text"><b>User(s)</b></p>-->
            <!--            <p class="dark-text"><b>Location</b></p>-->
            <!--            <p class="dark-text"><b>Span</b></p>-->
            <div>
                <label class="flex center fullwidth">
                    <div :style="colorStyle" />
                    <h3 class="margin-0">Calendar Appointment Color</h3>
                    <input type="color" v-model="rawColor" style="display: none" />
                </label>
            </div>
            <button class="primary top-40">Save</button>
        </section>

        <section class="tabbed-page password">
            <h3 class="margin-0">Change Password</h3>
            <div class="bottom-20">A link will be sent to your email to change your current password.</div>

            <BaseModal>
                <template #control="{ open }">
                    <button @click="open" class="primary top-20">Send Link</button>
                </template>
                <template #content="{ close }">
                    <div>
                        <h2 class="top-0">Change Password</h2>
                        <div class="bottom-20">
                            Click confirm to send a link to your email to change your password.
                        </div>
                        <div class="align-right top-20">
                            <button @click="close" class="secondary">Cancel</button>
                            <button @click="resetPassword(close)" class="primary">Confirm</button>
                        </div>
                    </div>
                </template>
            </BaseModal>
        </section>

        <section class="tabbed-page signature">
            <h3 class="margin-0">Your Signature</h3>
            <div class="bottom-20">
                This is the signature that will be displayed when you sign documents and forms that require your
                signature.
            </div>
            <div>
                <Signature />
                <!-- <div class="signature-box-click-to-add">
                    <button class="no-bg blue" @click="showSignatureModal = !showSignatureModal"><span class="material-icons-outlined">edit</span> Add Signature</button>

                    <v-if-workable-modal v-if="showSignatureModal" @close="closeModal">
                        <div>
                            <ScratchPad
                                @cancel="closeModal"
                                :idType="'user_id'"
                                :idValue="$store.state.user.id"
                                type="user"
                            />
                        </div>
                    </v-if-workable-modal>
                </div> -->
            </div>
        </section>

        <!-- <section class="tabbed-page signature"> -->

        <!-- <ChirpSettings v-if="!loading" :data_source="rows['misc']" :level="'company'" :level_id="level_id" /> -->
        <!-- </section> -->
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    const api_root = 'settings/list/user';

    import ChirpSettings from '@/components/general/list/ChirpSettings';
    import Signature from '@/components/signature/Signature';
    import BaseModal from '@/components/general/modals/BaseModal';
    import {
        auth,
        //signatures,
        users
    } from '@/util/apiRequests';
    import { debounce } from 'lodash';

    export default {
        name: 'UserSettings',
        components: {
            ChirpSettings,
            Signature,
            BaseModal
        },
        data() {
            return {
                loading: 1,
                rows: {
                    alert: [],
                    form: [],
                    notification: [],
                    tags: [],
                    misc: []
                },
                level_id: 0,
                showSignatureModal: false,
                test: false,
                colorLoaded: false,
                rawColor: ''
            };
        },
        computed: {
            ...mapState({
                user: 'user'
            }),
            header() {
                return this.headerText || this.pageMeta.title;
            },
            colorStyle() {
                const color = this.rawColor || '#447ef2';
                return `
                    height: 32px;
                    width: 32px;
                    border-radius: 50px;
                    margin-right: 10px;
                    background-color: ${color}`;
            }
        },
        methods: {
            async resetPassword(closeModal) {
                const errStr = 'Failed to send password reset email. Please try again later.';

                try {
                    const res = await this.$api.post(auth.resetPassword(), {
                        userId: this.user.id
                    });

                    if (res.status >= 400) {
                        //this.$toasted.error(errStr);
                        return;
                    }

                    this.$toasted.success('Password reset email sent.');
                } catch (err) {
                    //this.$toasted.error(errStr);
                } finally {
                    closeModal();
                }
            },
            async init() {
                this.level_id = this.$store.state.user.id;
                const res = await this.$api.get(`/${api_root}/${this.level_id}`);

                let d, row, obj;
                for (d in res.data) {
                    row = res.data[d];
                    if (row.value_options && row.value_options.length) {
                        try {
                            obj = JSON.parse(row.value_options);
                        } catch (e) {
                            obj = {};
                        }
                        if (obj.apiroot) {
                            const body = {
                                criteria: { page: { num_per_page: 100, page_num: 1 } }
                            };
                            // this is where need to grab the api list to render a dynamic dropdown
                            // row.value_options (STRING) = {"apiroot":"/email_templates", "text":"email_template_title", "value":"id"}
                            let apires = await this.$api.post(obj.apiroot, body);
                            let text = obj.text;
                            let value = obj.value;
                            obj = [{ text: ' -- Disabled --', value: 0 }];
                            for (let apival in apires.data.rows) {
                                let o2 = {
                                    text: apires.data.rows[apival][text],
                                    value: apires.data.rows[apival][value]
                                };
                                obj.push(o2);
                            }
                            row.value_options = JSON.stringify(obj);
                        }
                    }

                    switch (row.category) {
                        case 'alert':
                            this.rows['alert'].push(row);
                            break;
                        case 'form':
                            this.rows['form'].push(row);
                            break;
                        case 'notification':
                            this.rows['notification'].push(row);
                            break;
                        case 'tags':
                            this.rows['tags'].push(row);
                            break;
                        case 'payment': // not here
                        case 'insurance': // not here
                        case 'billing': // not here
                            break;
                        default:
                            this.rows['misc'].push(row);
                    }
                }

                // set the page-tab-input to be an input from the query params
                let tab = this.$route.query.tab;

                if (tab) {
                    const pageTabs = document.getElementsByName('page-tab-input');
                    pageTabs.forEach(t => t.checked = t.id === tab)
                }
                this.loading = 0;
            },
            openModal() {
                this.showSignatureModal = true;
                if (window.innerWidth >= 768) this.openModalOverlay();
            },
            closeModal(e) {
                if (typeof e != 'undefined' && e == 'modal' && window.innerWidth < 768) {
                    return;
                }
                this.showSignatureModal = false;
                this.closeModalOverlay();
            },
            // async getSignature() {
            //     let result = await this.$api.post(signatures.getList(), {
            //         criteria: { filter: { user_id: [this.$store.state.user.id] } },
            //     });
            //     if (result.data.rows.length > 0) {
            //         //
            //     }
            // },
            debounceColorChange: debounce(async function() {
                const res = await this.$api.put(users.updateUser(this.$store.state.user.id), {
                    user_color: this.rawColor
                });

                if (res.status < 200 || res.status >= 300) {
                    this.$toasted.error('Failed to update user color');
                    return;
                }

                this.$toasted.success('Updated calendar appointment color');
            }, 500),
            async getUserColor() {
                const res = await this.$api.get(users.getUserDetails(this.$store.state.user.id));

                if (res.status < 200 || res.status >= 300) {
                    this.rawColor = '#447ef2';
                    await this.$nextTick();
                    this.colorLoaded = true;
                    return;
                }

                this.rawColor = res.data.user_color || '#447ef2';
                await this.$nextTick();
                this.colorLoaded = true;
            }
        },
        async created() {
            await Promise.all([
                this.init(),
                // this.getSignature(),
                this.getUserColor()
            ]);
        },
        watch: {
            showSignatureModal() {
                if (this.showSignatureModal) {
                    if (window.innerWidth >= 768) this.openModalOverlay();
                }
            },
            rawColor() {
                if (this.colorLoaded) {
                    this.debounceColorChange();
                }
            }
        }
    };
</script>
