<template>
    <div>
        <div
            v-if="signatureExists"
            class="signature-display"
            @click="handleSignatureModalOpen"
            @mouseenter="hoveringSignatureBox = true"
            @mouseleave="hoveringSignatureBox = false"
        >
            <img v-if="!!signatureDataUrl" :src="signatureDataUrl" alt="signature" />

            <div v-if="hoveringSignatureBox" class="signature-display__overlay">
                <p><span class="material-icons-outlined">edit</span> Edit Signature</p>
            </div>
        </div>

        <div v-else class="signature-display" @click="handleSignatureModalOpen">
            <p><span class="material-icons-outlined">edit</span> Add Signature</p>
        </div>

        <NoButtonModal :open="signatureModalOpen" @close="handleSignatureModalClose">
            <div v-if="!signatureExists">
                <h2 class="margin-0">Add Signature</h2>
                <p class="top-0">Use your mouse or touchpad to add your signature to the box below.</p>
            </div>

            <div v-else>
                <h2>Edit Signature</h2>
                <p>Use your mouse or touchpad to edit your signature in the box below.</p>
            </div>

            <VueSignaturePad class="signature-pad" ref="addSignature" />
            <div class="button-container align-right signature-btns">
                <button class="bottom-0 signature-button" @click="handleSignatureModalClose">Cancel</button>
                <button class="bottom-0 signature-button" @click="clearSignature">Clear</button>
                <button class="primary bottom-0 signature-button" @click="saveSignature">Save</button>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import { signatures } from '@/util/apiRequests';
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { mapState } from 'vuex';

    export default {
        name: 'Signature',
        components: { NoButtonModal },
        data() {
            return {
                signatureInfo: {},
                hoveringSignatureBox: false,
                signatureModalOpen: false,
            };
        },
        computed: {
            ...mapState({ user: 'user' }),
            userId() {
                return this.user.id;
            },
            signaturePad() {
                return this.$refs.addSignature;
            },
            signatureExists() {
                return Object.keys(this.signatureInfo).length > 0;
            },
            signatureDataUrl() {
                if (this.signatureInfo.file?.Body) {
                    return `data:image/png;base64, ${this.signatureInfo.file.Body}`;
                }

                return null;
            },
        },

        methods: {
            async handleSignatureModalOpen() {
                this.signatureModalOpen = true;
                await this.$nextTick();

                this.signaturePad.resizeCanvas();

                this.signaturePad.fromDataURL(
                    this.signatureInfo?.file?.Body
                        ? `data:image/png;base64, ${this.signatureInfo.file.Body}`
                        : undefined
                );
            },
            handleSignatureModalClose() {
                this.signatureModalOpen = false;
                this.signaturePad.clearSignature();
            },
            async handleSubmit() {
                this.signatureModalOpen = false;

                await this.getSignature();
            },
            clearSignature() {
                this.signaturePad.clearSignature();
            },
            async getSignature() {
                const res = await this.$api.get(signatures.getSignature(this.user.id), { params: { t: 'user' } });

                if (res.status === 404) {
                    return;
                }

                if (res.status < 200 || res.status >= 300) {
                    //this.$toasted.error('Failed to retrieve signature data');
                    return;
                }

                this.signatureInfo = res.data || {};
            },
            async saveSignature() {
                const { data, isEmpty } = this.signaturePad.saveSignature();
                if (!isEmpty) {
                    const file = new FormData();
                    file.append('file', this.dataURIToBlob(data));
                    file.append('signature', data);
                    file.append('resourceId', this.user.id);
                    file.append('type', 'user');
                    file.append('fileId', this.signatureInfo.file_id || null);

                    const res = await this.$api.post(signatures.saveSignature(), file);

                    if (res.status < 200 || res.status >= 300) {
                        //this.$toasted.error('Failed to save signature. Please try again later.');
                        return;
                    }

                    this.signatureInfo.file_id = res.data.fileId;
                    this.signatureInfo.id = res.data.signId;

                    this.$toasted.success('New signature saved');
                    await this.handleSubmit();
                }
            },
            dataURIToBlob(dataURI) {
                const splitDataURI = dataURI.split(',');
                const byteString =
                    splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
                const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

                const ia = new Uint8Array(byteString.length);
                for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

                return new Blob([ia], { type: mimeString });
            },
        },
        async created() {
            await this.getSignature();
        },
    };
</script>
